module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://robinwalter.me","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900","Satisfy:400","Source Code Pro:300,300i,400,400i,600,600i,700,700i"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"description":"Mein Name ist Robin Walter. Die Entwicklung und Gestaltung von Software, wie mobile Apps und Websites, gehören zu meinen Hobbys.","language":"de","metaTags":[{"content":"v1.1.0","property":"version"}],"openGraph":{"locale":"de_DE","site_name":"Robin Walter – Meine persönliche Website","type":"website","url":"https://robinwalter.me"},"titleTemplate":"%s | Robin Walter – Meine persönliche Website","twitter":{"cardType":"summary"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#26252D","description":"Mein Name ist Robin Walter. Die Entwicklung und Gestaltung von Software, wie mobile Apps und Websites, gehören zu meinen Hobbys.","display":"standalone","icon":"src/assets/images/icon-light.png","lang":"de","name":"Robin Walter – Meine persönliche Website","short_name":"robinwalterfit","start_url":"/","theme_color":"#425328","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dec8d2cdc5846142a8b0f81f0d6c5c8c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"cookieDomain":".robinwalter.me","dev":true,"disableCookies":false,"exclude":["/offline-plugin-app-shell-fallback/"],"localScript":null,"matomoJsScript":"matomo.js","matomoPhpScript":"matomo.php","matomoUrl":"https://stats.robinwalter.me","requireConsent":true,"siteId":1,"siteUrl":"https://robinwalter.me","trackLoad":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/index.html","/datenschutz","/impressum"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
