/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require( './src/styles/robinwalter.scss' )

const Icons    = require( 'uikit/dist/js/uikit-icons' )
const ReactDOM = require( 'react-dom' )
const UIkit    = require( 'uikit' )

exports.onClientEntry = () => {
    window._paq.push( [ 'setDoNotTrack', true ] )
}

exports.onInitialClientRender = () => {
    // loads the Icon plugin
    UIkit.use( Icons )
}

exports.replaceHydrateFunction = () => {

    return ( element, container, callback ) => {
        ReactDOM.hydrate( element, container, callback )
        // loads the Icon plugin
        UIkit.use( Icons )
    }

}
